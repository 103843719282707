/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-04 11:10:07
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-04 19:20:32
 * @Description: 价格查询/药品限价管理总路由
 */

import View from '@/components/view';
import NationalFixedPrice from './national-fixed-price';
import ProvinceFixedPrice from './province-fixed-price';

export default [
    {
        path: 'drug-fixed-price-manage',
        component: View,
        meta: {
            title: '药品限价管理'
        },
        children: [
            ...NationalFixedPrice,
            ...ProvinceFixedPrice
        ]
    }
];
