/*
 * @Author: yanchunl
 * @Email: yanchunl@hxmec.com
 * @Date: 2021-06-29 15:33:59
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-10-28 18:07:01
 * @Description: 报量系统
 * @Route: 
 */
import View from '@/components/view';
// 约定量
import Agreed from './agreed-quantity';
// 历史数据
import Historical from './historical-data-manage';
// 报量目录
import Catalogue from './volume-catalogue';
// 挂网价格填报
import PriceFill from './price-fill';
// 耗材填报数据统计
import FillStatistics from './fill-statistics';
// 医保局采购数据汇总
import procurementMedicalDataSummary from './procurement-medical-data-summary';
// 联采办采购数据汇总
import procurementJointDataSummary from './procurement-Joint-data-summary';

// 审核上报
import PurchaseDataReport from './purchase-data-report';

export default [
    {
        path: '/report',
        component: View,
        meta: {
            title: '报量系统'
        },
        children: [
            ...Agreed,
            ...Historical,
            ...Catalogue,
            ...PriceFill,
            ...FillStatistics,
            ...procurementMedicalDataSummary,
            ...procurementJointDataSummary,
            ...PurchaseDataReport
        ]
    }
];
