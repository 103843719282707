/*
 * @Author: yanchunl
 * @Email: yanchunl@hxmec.com
 * @Date: 2021-07-01 09:32:37
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-09-23 10:34:24
 * @Description: 历史数据管理
 * @Route: Route
 */
import View from '@/components/view';

const Fill = () => import(/* webpackChunkName: "historical-data-fill" */ '@/pages/report/historical-data-manage/historical-data-fill');
const FillVarieties = () => import(/* webpackChunkName: "historical-data-fill-varieties" */ '@/pages/report/historical-data-manage/varieties/historical-data-fill');
const FillVarieties2 = () => import(/* webpackChunkName: "historical-data-fill-varieties" */ '@/pages/report/historical-data-manage/varieties/historical-data-fill2');
const HistoricalDataStatistics = () => import(/* webpackChunkName: "historical-data-statistics" */ '@/pages/report/historical-data-manage/historical-data-statistics/new-index');
const HistoricalProductDetail = () => import(/* webpackChunkName: "historical-product-detail" */ '@/pages/report/historical-data-manage/historical-data-statistics/historical-product-detail');
const HistoricalInstitutionDetail = () => import(/* webpackChunkName: "historical-institution-detail" */ '@/pages/report/historical-data-manage/historical-data-statistics/historical-institution-detail');
const HistoricalFilStatus = () => import(/* webpackChunkName: "historical-fill-status" */ '@/pages/report/historical-data-manage/historical-data-statistics/historical-fill-status');
export default [
    {
        path: 'historical-data-manage',
        meta: {
            title: '历史数据管理'
        },
        component: View,
        children: [
            {
                path: 'historical-data-fill-varieties',
                component: FillVarieties,
                meta: {
                    title: '历史数据填报'
                }
            },
            {
                path: 'historical-data-fill-varieties2',
                component: FillVarieties2,
                meta: {
                    title: '历史数据填报'
                }
            },
            {
                path: 'historical-data-fill',
                component: Fill,
                meta: {
                    title: '历史数据填报'
                }
            },
            {
                path: 'historical-data-statistics',
                component: HistoricalDataStatistics,
                meta: {
                    title: '历史采购数据统计'
                }
            },
            {
                path: 'historical-data-statistics/historical-product-detail',
                component: HistoricalProductDetail,
                meta: {
                    title: '查看详情（按产品统计）'
                }
            },
            {
                path: 'historical-data-statistics/historical-institution-detail',
                component: HistoricalInstitutionDetail,
                meta: {
                    title: '查看详情（按医疗机构）'
                }
            },
            {
                path: 'historical-fill-status',
                component: HistoricalFilStatus,
                meta: {
                    title: '历史采购量填报进度跟踪'
                }
            }
        ]

    }
];
