import {
    SET_USERINFO,
    CLEAR_USERINFO,
    SET_MENUID,
    GET_APPLY_MENU,
    SET_PAGE_TAGS,
    DELETE_PAGE_TAGS,
    CLEAR_PAGE_TAGS,
    SET_BREADCRUMB_DATA,
    SET_ISIMPLANT_FLAG,
    GET_REGION_LIST,
    GET_QUANTITY_LIST,
    SAVE_USER_MODULE_NUM
} from './mutation-type';

export default {
    [SET_USERINFO](state, data) {
        state.userInfo = data;
    },
    [CLEAR_USERINFO](state) {
        state.userInfo = null;
    },
    [SET_MENUID](state, data) {
        state.menuID = data.menuid;
    },
    // 获取应用菜单
    [GET_APPLY_MENU](state, data) {
        state.applyMeunList = data;
    },
    // 设置页签数据
    [SET_PAGE_TAGS](state, data) {
        const { pageTagsData } = state;
        if (pageTagsData.length === 5) {
            state.pageTagsData.shift();
        }
        const index = pageTagsData.findIndex(tag => JSON.stringify(tag) === JSON.stringify(data));
        if (index === -1) {
            state.pageTagsData.push(data);
        }
    },
    // 删除页签数据
    [DELETE_PAGE_TAGS](state, data) {
        state.pageTagsData.splice(data, 1);
    },
    // 清除空页签数据
    [CLEAR_PAGE_TAGS](state) {
        state.pageTagsData = [];
    },
    // 设置面包屑
    [SET_BREADCRUMB_DATA](state, data) {
        state.breadData = data;
    },
    // 设置是否内嵌门户标志
    [SET_ISIMPLANT_FLAG](state, data) {
        state.isImplant = data;
    },
    // 设置地区
    [GET_REGION_LIST](state, data) {
        state.regionList = data;
    },
    // 设置带量采购项目
    [GET_QUANTITY_LIST](state, data) {
        state.quantityList = data;
    },
    // 保存用户模块数量
    [SAVE_USER_MODULE_NUM](state, data) {
        state.userModuleNum = data;
    }
};