/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-04 14:06:42
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-14 16:49:39
 * @Description: 价格查询/国家谈判药品价格查询相关路由
 */

const List = () => import(/* webpackChunkName: "negotiate-drug-query-list" */ '@/pages/bdc/price-query/negotiate-drug-query/list');
export default [
    {
        path: 'negotiate-drug-query/list',
        component: List,
        meta: {
            title: '国家谈判药品价格查询'
        }
    }
];
