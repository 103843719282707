import View from '@/components/view';
import BaseDrug from './base-drug-manage';
import HealthcareDrug from './healthcare-drug-manage';
import MonitorDrug from './monitor-drug-manage';
import UniformityDrug from './uniformity-drug-manage';

export default [
    {
        path: 'product-policy-attr-manage',
        component: View,
        meta: {
            title: '产品政策属性管理'
        },
        children: [
            ...BaseDrug,
            ...HealthcareDrug,
            ...UniformityDrug,
            ...MonitorDrug
        ]
    }
];
