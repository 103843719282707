/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-09-11 16:52:05
* @Description: 基本业务服务/公共信息公示管理相关路由
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/public-service/public-info/list');
const Read = () => import(/* webpackChunkName: "read" */ '@/pages/bdc/public-service/public-info/read');
const Add = () => import(/* webpackChunkName: "add" */ '@/pages/bdc/public-service/public-info/add');
const Edit = () => import(/* webpackChunkName: "edit" */ '@/pages/bdc/public-service/public-info/edit');
export default [
    {
        path: 'public-info/list',
        component: List,
        meta: {
            title: '公共信息公示管理'
        }
    },
    {
        path: 'public-info/list/read',
        component: Read,
        meta: {
            title: '查看公示信息',
            display: 'none'
        }
    },
    {
        path: 'public-info/list/add',
        component: Add,
        meta: {
            title: '新增公示信息',
            display: 'none'
        }
    },
    {
        path: 'public-info/list/edit',
        component: Edit,
        meta: {
            title: '编辑公示信息',
            display: 'none'
        }
    }

];
