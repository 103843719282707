/*
 * @Author: zhujial
 * @Email: zhujial@hxmec.com
 * @Date: 2023-05-23 11:08:06
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-06-01 15:28:46
 */
import View from '@/components/view';

// 基础数据管理
const AllocationList = () => import(/* webpackChunkName: "allocation-list" */ '@/pages/allocation/hosp-org/allocation-manage/allocation-list'); // 约定采购量分配
const AllocationDetails = () => import(/* webpackChunkName: "allocation-details" */ '@/pages/allocation/hosp-org/allocation-manage/allocation-details'); // 约定采购量分配明细
const FixedAllocationQuery = () => import(/* webpackChunkName: "fixed-allocation-query" */ '@/pages/allocation/hosp-org/allocation-manage/fixed-allocation-query'); // 固定分配量查询
const PrepurcDataQuery = () => import(/* webpackChunkName: "prepurc-data-query" */ '@/pages/allocation/hosp-org/allocation-manage/prepurc-data-query'); // 预采购数据查询

export default [
    {
        path: 'hosp-org',
        component: View,
        meta: {
            title: '医疗机构'
        },
        children: [
            {
                path: 'allocation-manage',
                component: View,
                meta: {
                    title: '约定采购量分配管理'
                },
                children: [
                    {
                        path: 'allocation-list',
                        component: AllocationList,
                        meta: {
                            title: '约定采购量分配'
                        }
                    },
                    {
                        path: 'allocation-details',
                        component: AllocationDetails,
                        meta: {
                            title: '约定采购量分配明细'
                        }
                    },
                    {
                        path: 'fixed-allocation-query',
                        component: FixedAllocationQuery,
                        meta: {
                            title: '中选企业定向分配量查询'
                        }
                    },
                    {
                        path: 'prepurc-data-query',
                        component: PrepurcDataQuery,
                        meta: {
                            title: '预采购数据查询'
                        }
                    }
                ]
            }

        ]
    }
];
