/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-04 14:04:55
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-14 16:49:24
 * @Description: 价格查询/麻精药品价格查询相关路由
 */

const List = () => import(/* webpackChunkName: "nap-drug-price-query-list" */ '@/pages/bdc/price-query/nap-drug-price-query/list');
export default [
    {
        path: 'nap-drug-price-query/list',
        component: List,
        meta: {
            title: '麻精药品价格查询'
        }
    }
];
