export default {
    responseProps: {
        code: 'code', // 响应状态码
        data: 'data', // 响应数据
        message: 'msg', // 响应文案信息
        successCode: 0 // 文件上传联调
    },
    responseListProps: {
        results: 'records', // 响应数组数据
        pageSize: 'size', // 页长
        pageNumber: 'current', // 页码
        totalCount: 'total', // 页面数据总量
        cursorCode: 'cursorCode' // 游标标记字段
    },
    // 文件响应数据键值
    responseFileProps: {
        fileId: 'fileId', // 文件标识字段
        fileUrl: 'fileUrl', // 文件地址
        fileName: 'originalFileName', // 文件名
        fileSuffix: 'fileExt' // 文件后缀
    },
    tableColumnDensity: false,
    // 验证码
    vcodePath: '/code/code',
    // 文件预览
    filePath: '/web/comp/file/query',
    // 文件下载方式是否为新，默认false
    downloadFlag: true,
    // 服务
    baseOss: {
        '/web/tender': '/hsaRpup/tps-rpup',
        '/web/trans': '/hsaRpup/tps-rpup',
        '/web/trade': '/hsaRpup/tps-rpup',
        '/web/rupu': '/hsaRpup/tps-rpup',
        '/web/auth': '/hsaRpup/tps-rpup',
        '/web/bdc': '/hsaRpup/tps-rpup',
        '/web/rpup': '/hsaRpup/tps-rpup',
        '/web/comp': '/hsaRpup/tps-rpup',
        '/web/rupufl': '/hsaRpup/tps-rpup'
    }
};
