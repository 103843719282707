/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-04 11:15:24
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-04 19:20:43
 * @Description: 价格查询/药品限价管理/省级限价管理
 */

const List = () => import(/* webpackChunkName: "province-fixed-price-list" */ '@/pages/bdc/price-query/drug-fixed-price-manage/province-fixed-price/list');
const Edit = () => import(/* webpackChunkName: "province-fixed-price-edit" */ '@/pages/bdc/price-query/drug-fixed-price-manage/province-fixed-price/edit');

export default [
    {
        path: 'province-fixed-price/list',
        component: List,
        meta: {
            title: '省级限价管理'
        }
    },
    {
        path: 'province-fixed-price/list/read',
        name: 'province-fixed-price-read',
        component: Edit,
        meta: {
            title: '查看限价',
            display: 'none'
        }
    },
    {
        path: 'province-fixed-price/list/add',
        name: 'province-fixed-price-add',
        component: Edit,
        meta: {
            title: '新增限价',
            display: 'none'
        }
    },
    {
        path: 'province-fixed-price/list/edit',
        name: 'province-fixed-price-edid',
        component: Edit,
        meta: {
            title: '编辑限价',
            display: 'none'
        }
    }
];
