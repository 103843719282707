/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2020-03-17 17:33:05
* @Description: 机构与编码同步管理路由
*/
import View from '@/components/view';
import DrugConsumableUnit from './drug-consumable-unit-manage';
import MedicialInformation from './medical-information-manage';
import ProductExpressInformation from './product-express-information-manage';
import DrugConsumableCode from './drug-consumable-code-manage';
import ExpertInformation from './expert-information';
import SortCode from './sort-code';

export default [
    {
        path: 'mechanism-code-manage',
        component: View,
        meta: {
            title: '机构与编码查询'
        },
        children: [
            ...DrugConsumableUnit,
            ...MedicialInformation,
            ...ProductExpressInformation,
            ...DrugConsumableCode,
            ...ExpertInformation,
            ...SortCode
        ]
    }
];
