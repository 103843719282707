/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2020-01-01 14:17:28
 * @Last Modified by: maozg
 * @Last Modified time: 2020-01-01 14:47:22
 * @Description: 药品-工作台
 */
const MedHome = () => import('@/pages/trade/home/drug/med-home');
const DelvHome = () => import('@/pages/trade/home/drug/delv-home');
const AdmHome = () => import('@/pages/trade/home/drug/adm-home');

const routes = [
    {
        path: 'drug/med/home',
        component: MedHome,
        meta: {
            title: '工作台'
        }
    },
    {
        path: 'drug/delv/home',
        component: DelvHome,
        meta: {
            title: '工作台'
        }
    },
    {
        path: 'drug/adm/home',
        component: AdmHome,
        meta: {
            title: '工作台'
        }
    }
];

export default routes;