/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-09-11 16:40:41
* @Description: 药品和医用耗材编码同步管理/医用耗材编码同步管理相关路由
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/mechanism-code-mangage/drug-consumable-code-manage/consumable-code-manage/list');
const Detail = () => import(/* webpackChunkName: "detail" */ '@/pages/bdc/mechanism-code-mangage/drug-consumable-code-manage/consumable-code-manage/detail');

export default [
    {
        path: 'consumable-code/list',
        component: List,
        meta: {
            title: '医用耗材编码查询'
        }
    },
    {
        path: 'consumable-code/list/detail',
        component: Detail,
        meta: {
            title: '医用耗材编码查询详情',
            display: 'none'
        }
    }

];
