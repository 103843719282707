
/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:52:10
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-11-03 15:20:09
 * @Description: 工具函数
 * @Route: Route
 */
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import Vue from 'vue';
import dayjs from 'dayjs';
import { portalMessageSend } from '@ncp-web/portal-communication';
// import JITHTTPCLIENT from './CA/JITHTTPCLIENT';

const env = process.env.NODE_ENV;
const vm = new Vue();
/*
 * @description 获取地址栏参数
 */
export const getUrlParame = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('?');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('?') + 1);
    params = params.split('#');
    params = params[0].split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * @description 获取地址栏hash参数
 */
export const getUrlHash = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('#');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('#') + 1);
    params = params.split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * 判断浏览器类型是否为IE
 */
export const isIE = () => {
    const u = window.userAgent || navigator.userAgent;
    return u.indexOf('MSIE') > 0 || u.indexOf('Trident') > 0;
};

/*
 * 判断对象是否为空
 */
export const isEmptyObj = obj => (
    !obj || Object.keys(obj).length === 0
);

/**
 * 判断目标对象是否含有指定字段名
 * @param {Object} obj 目标对象
 * @param {String} field 字段名
 */
export const hasField = (obj, field) => {
    if (!obj || isEmptyObj(obj)) return false;
    const keyList = Object.keys(obj).map(key => key);
    return keyList.includes(field);
};

/**
 * 日期格式化
 * (new Date(), 'yyyy-mm-dd hh:ii:ss.S')==> 2006-07-02 08:09:04.423
 * (new Date(), 'yyyy-mm-dd E HH:ii:ss') ==> 2009-03-10 二 20:09:04
 * (new Date(), 'yyyy-mm-dd EE hh:ii:ss') ==> 2009-03-10 周二 08:09:04
 * (new Date(), 'yyyy-mm-dd EEE hh:ii:ss') ==> 2009-03-10 星期二 08:09:04
 * (new Date(), 'yyyy-m-d h:m:s.S') ==> 2006-7-2 8:9:4.18
 * (new Date(), 'yyyy/mm/dd hh:ii:ss.S') ==> 2017/04/17 10:00:48.282
 */
export const formatDate = (value, fmt) => {
    if (!value) {
        return null;
    }
    // ie
    let newFmt = fmt;
    let date = value;
    if (String(date).match('-')) {
        date = date.replace('-', '/');
    }
    /* eslint-disable no-param-reassign */
    date = new Date(date);
    /* eslint no-console: 'off' */
    if (date.toString() === 'Invalid Date') {
        console.log('日期不合法');
    }
    const o = {
        'M+': date.getMonth() + 1, // 月份,
        'm+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 24 === 0 ? 0 : date.getHours() % 24, // 小时
        'H+': date.getHours(), // 小时
        'i+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    const week = {
        0: '/u65e5',
        1: '/u4e00',
        2: '/u4e8c',
        3: '/u4e09',
        4: '/u56db',
        5: '/u4e94',
        6: '/u516d'
    };
    if (/(y+)/.test(newFmt)) {
        newFmt = newFmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(newFmt)) {
        newFmt = newFmt.replace(RegExp.$1, (match, p1) => {
            if (p1.length > 1) {
                return (p1.length > 2 ? '/u661f/u671f' : '/u5468') + week[`${date.getDay()}`];
            }
            return week[`${date.getDay()}`];
        });
    }
    const keys = Object.keys(o);
    for (let k = 0, len = keys.length; k < len; k += 1) {
        const prop = keys[k];
        if (new RegExp(`(${prop})`).test(newFmt)) {
            newFmt = newFmt.replace(RegExp.$1, (RegExp.$1.length === 1) ?
                (o[prop]) : ((`00${o[prop]}`).substr((`${o[prop]}`).length)));
        }
    }
    /* eslint-disable consistent-return */
    return newFmt;
};

/**
 * 判断是否为日期
 * @param {*} date 要判断是否为日期的值
 */
export const isDate = date => {
    if (date === null || date === undefined) return false;
    if (Number.isNaN(new Date(date).getTime())) return false;
    if (Array.isArray(date)) return false; // deal with `new Date([ new Date() ]) -> new Date()`
    return true;
};

/**
 * 获取指定时间前几个月或几年的时间戳
 * @param {timestamp} time 目标时间
 * @param {Object} obj 时间区间跨度
 * @param {String || Number} obj.range 时间区间
 * @param {String} obj.dimension 时间维度：年 || 月
 */
export const getHistoryRangeTimestamp = (time, obj = {}) => {
    if (!isDate(time)) return;
    let value = `${time}-01`; // 补齐日期，为兼容IE
    if (String(value).match('-')) {
        value = value.replace(/-/g, '/');
    }
    const { range = 1, dimension = 'month' } = obj;
    const count = dimension === 'month' ? parseInt((range / 12), 10) : range;
    const monthR = range % 12;
    const year = formatDate(value, 'yyyy') * 1;
    const month = new Date(value).getMonth() + 1;
    const monthT = month - monthR + 1;
    let yearF = year - count;
    let monthF = month;
    if (dimension === 'month') {
        yearF = monthT < 1 ? year - 1 : year;
        monthF = monthT < 1 ? monthT + 12 : monthT;
    }
    return new Date(`${yearF}/${monthF}/01`).getTime();
};

/**
 * 获取指定时间前几天或几个月或几年的时间
 * @param {timestamp} time 目标时间
 * @param {Object} obj 时间区间跨度
 * @param {String || Number} obj.range 时间区间
 * @param {String} obj.dimension 时间维度：year || month || day
 * @param {String} format 时间类型
 */
export const getHistoryRangeDate = (time, obj = {}, format = 'YYYY-MM') => {
    if (!isDate(time)) return;
    const { range = 1, dimension = 'year' } = obj;
    return dayjs().subtract(range, dimension).format(format);
};

/*
 * 下载全路径文件
 */
export const downloadFile = (url, cb) => {
    const xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function anonymous() {
        if (this.status === 200) {
            cb(this.response);
        }
    };
    xhr.send();
};

/*
 * 深度遍历获取目标
 * param {Array} source 源数据
 * param {String} target 目标值
 */
export const findTargetByDeepTraverse = (source, target, result = []) => {
    const localSource = JSON.parse(JSON.stringify(source));
    localSource.forEach(item => {
        if (item.name.indexOf(target) !== -1) {
            result.push(item);
        } else if (item.children && item.children.length) {
            findTargetByDeepTraverse(item.children, target, result);
        }
    });
    return result;
};

/*
 * 区分监管端项目内耗材和药品
 * param {String} path 页面路径
 */
export const findProjectType = path => {
    const drugReg = /\/drug\//;
    const conReg = /\/consumable\//;
    let urlStr = '';
    if (drugReg.test(path)) {
        urlStr = 'drug';
    }
    if (conReg.test(path)) {
        urlStr = 'consumable';
    }
    return urlStr;
};

/**
 * 倒计时
 * @param {String} target 目标时间
 */
export const countDown = target => {
    const oDate = new Date();// 获取日期对象
    const oldTime = oDate.getTime();// 现在距离1970年的毫秒数
    // 判断是否为Safari、火狐浏览器
    const u = window.userAgent || navigator.userAgent;
    const isSafariFirefox = u.indexOf('Safari') !== -1 || u.indexOf('Firefox') !== -1;
    const newDate = new Date((isIE() || isSafariFirefox) && String(target).match('-') ? target.replace(/-/g, '/') : target);
    const newTime = newDate.getTime();// 2019年距离1970年的毫秒数
    if (newTime >= oldTime) {
        let second = Math.floor((newTime - oldTime) / 1000);// 未来时间距离现在的秒数
        const day = Math.floor(second / 86400);// 整数部分代表的是天；一天有24*60*60=86400秒 ；
        second %= 86400;// 余数代表剩下的秒数；
        const hour = Math.floor(second / 3600);// 整数部分代表小时；
        second %= 3600; // 余数代表 剩下的秒数；
        const minute = Math.floor(second / 60);
        second %= 60;
        return {
            day,
            hour,
            minute,
            second
        };
    }
    return false;
};

/*
 * @description 密码加密
 */
export const encryptPass = word => {
    const encrypted = CryptoJS.SHA256(word);
    return encrypted.toString();
};

/*
 * param {String} word 待加密的字符串
 * param {String} keyStr aes加密需要用到的16位字符串的key
 */
export const encrypt2 = data2 => {
    let key = '2dskjk3j42hjkdsh';
    let result = '';
    key = CryptoJS.enc.Latin1.parse(key);
    const iv = key;
    // 加密
    const encrypted = CryptoJS.AES.encrypt(
        data2,
        key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        }
    );
    result = encrypted.toString();
    return result;
};

/*
 * param {String} word 待加密的字符串
 * param {String} keyStr aes加密需要用到的16位字符串的key
 */
export const encrypt = (word, keyStr) => {
    const defKey = keyStr || 'Lic6J8807nW5K674';
    const key = CryptoJS.enc.Utf8.parse(defKey);
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(
        srcs,
        key,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return encrypted.toString();
};

/*
 * param {String} word 待解密的字符串
 * param {String} keyStr aes解密需要用到的16位字符串的key
 */
export const decrypt = (word, keyStr) => {
    const defKey = keyStr || 'Lic6J8807nW5K674';
    const key = CryptoJS.enc.Utf8.parse(defKey);
    const decrypted = CryptoJS.AES.decrypt(
        word,
        key,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return CryptoJS.enc.Utf8.stringify(decrypted).toString();
};

/**
 * 对象深度拷贝
 * @param {Object} obj 拷贝对象
 */
export const deepCopy = obj => (oldObj => {
    // 定义一个新的空对象
    let newObject = {};
    // 判断原对象是否存在
    if (oldObj) {
        // 判断原对象的类型
        if (oldObj.constructor === Object) {
            newObject = new oldObj.constructor();
        } else {
            newObject = new oldObj.constructor(oldObj.valueOf());
        }
        // 遍历克隆原对象的属性
        Object.keys(oldObj).forEach(key => {
            if (newObject[key] !== oldObj[key]) {
                if (typeof (oldObj[key]) === 'object') {
                    // 对象内部的子对象
                    newObject[key] = deepCopy(oldObj[key]);
                } else {
                    newObject[key] = oldObj[key];
                }
            }
        });
        // 克隆原对象的常用方法
        newObject.toString = oldObj.toString;
        newObject.valueOf = oldObj.valueOf;
        return newObject;
    }
})(obj);

/**
 * 对象深度合并
 * @param {Object} initObj 原始对象
 * @param {Object} targetObj 合并对象
 */
export const deepMergeObj = (initObj, targetObj) => {
    const resultObj = initObj;
    const keys = Object.keys(targetObj);
    keys.forEach(key => {
        resultObj[key] = initObj[key] && initObj[key].toString() === '[object Object]' ? deepMergeObj(initObj[key], targetObj[key]) : targetObj[key];
    });
    return resultObj;
};

/**
 * 数据转换
 * @param {String} convert 转换数字
 */
export const dataConvert = convert => {
    let defConvert = convert || 0;
    if (typeof defConvert === 'string') {
        defConvert = parseFloat(defConvert);
    }
    if (defConvert >= 10000 && defConvert < 10000 * 10000) {
        defConvert /= 10000;
        defConvert = `${defConvert.toFixed(2)}万`;
    } else if (defConvert >= 10000 * 10000) {
        defConvert /= (10000 * 10000);
        defConvert = `${defConvert.toFixed(2)}亿`;
    }
    return defConvert;
};
/**
 * 根据不同环境，处理请求头
 */
export const headerConfig = token => {
    let headerObj;
    const jwtData = token || localStorage.getItem('token');
    if (env === 'fiveTest' || env === 'fiveProd') {
        const refreshToken = localStorage.getItem('refreshToken');
        const accountType = localStorage.getItem('accountType');
        headerObj = {
            Authorization: jwtData || '',
            refreshToken: refreshToken || '',
            accountType: accountType || '',
            'X-XSRF-TOKEN': vm.$cookies.get('XSRF-TOKEN')
        };
    } else {
        headerObj = {
            Authorization: `Bearer ${jwtData}`
        };
    }
    return headerObj;
};
/*
 *  处理文件流
 *  @param {string}  url  请求url
 *  @param {object} param  请求传参
 *  @param {string} fileName  文件名称
 *  @param {boolean} notFull 请求地址是否是全路径标志
 *  全路径是文件服务地址，不需要token和baseUrl；不是全路径，本地不需要baseUrl，测试环境需要，同时需要token
 */
export const DownLoader = (url, param, fileName) => {
    // const vm = new Vue();
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : '';
    Axios({
        method: 'get',
        url,
        data: param,
        headers,
        responseType: 'blob'
    }).then(res => {
        const { data } = res;
        if (data.type !== 'application/json') {
            const blob = new Blob([data]);
            // 非IE下载
            if ('download' in document.createElement('a')) {
                const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }
            // IE、edge
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            }
        } else {
            // 通过FileReader来获取异常result
            const reader = new FileReader();
            reader.readAsText(data, 'utf-8');
            reader.onload = e => {
                const msg = JSON.parse(e.target.result);
                if (Object.prototype.hasOwnProperty.call(msg, 'code')) {
                    vm.$message.error(msg.detail);
                    return false;
                }
            };
        }
    }).catch(err => {
        console.log(err);
        vm.$message.error('未查到文件');
    });
};

/**
 * 深度拷贝
 */
export const cloneCopy = obj => {
    if (obj == null) { return null; }
    const result = Array.isArray(obj) ? [] : {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === 'object') {
                result[key] = cloneCopy(obj[key]); // 如果是对象，再次挪用该要领本身
            } else {
                result[key] = obj[key];
            }
        }
    }
    return result;
};

/**
 * 集成门户跳转菜单统一方法
 */
export const skipPage = param => {
    if (!param) return;
    if (param.constructor === Object) {
        const { menuId, urlParam } = param;
        portalMessageSend.openMenu({
            // 一级菜单编号
            firstMenuId: 'tps-local',
            // 菜单编号
            menuId,
            // url参数
            urlParam
        });
    }
};

/**
 * 集成门户隐藏菜单方法
 */
export const hideMenu = param => {
    if (!param) return;
    if (param.constructor === Object) {
        const { menuIds } = param;
        portalMessageSend.message({
            action: 'hideMenusById',
            data: {
                firstMenuId: 'tps-local',
                menuIds
            }
        });
    }
};


/**
 * 集成门户统一设置参数方法
 */
export const setParam = param => {
    if (!param) return;
    if (param.constructor === Object) {
        const { menuIds, urlParam } = param;
        // 预置项目内菜单参数
        portalMessageSend.message({
            action: 'batchSetPresetParams',
            data: {
                menuIds,
                firstMenuId: 'tps-local',
                urlParam
            }
        });
    }
};

/**
 * 关闭浏览器当前窗口，只适用于window.open()打开的页签
 */
export const closeWebPage = () => {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
            window.opener = null;
            window.close();
        } else {
            window.open('', '_top');
            window.top.close();
        }
    } else if (navigator.userAgent.indexOf('Firefox') > 0) {
        window.location.href = 'about:blank ';
        window.close();
    } else {
        window.opener = null;
        window.open('', '_self', '');
        window.close();
    }
};

/**
 * 截取指定字符后的内容
 * @param path 截取内容
 * @param str 字符
 */
export const getCaption = (path, str) => {
    const index = path.lastIndexOf(str);
    return path.substring(index + 1, path.length);
};

/**
 * 上传附件校验
 * @param {Array} typeArr 接收上传文件的类型
 * @param {Object} file 文件数据
 * @param {String} msg 错误文案提示
 */
export const limitsFile = (file, typeArr, msg) => {
    const { name } = file;
    const fileType = getCaption(name, '.');
    if (typeArr.includes(fileType)) {
        return true;
    }
    vm.$message.error(msg);
    return false;
};

/**
     * 获取两个数小数点的长度最大值，然后取10的次幂
     * @param {*} num1 
     * @param {*} num2 
     */

export const getPointPow = (num1, num2) => {
    if (typeof num2 !== 'number') {
        throw new TypeError('数字不合法');
    }
    let pointLength1 = 0; let pointLength2 = 0;
    // 如果存在小数点
    if ((`${num1}`).indexOf('.') > -1) {
        pointLength1 = (`${num1}`).split('.')[1].length;
    }
    if ((`${num2}`).indexOf('.') > -1) {
        pointLength2 = (`${num2}`).split('.')[1].length;
    }
    return window.Math.pow(10, Math.max(pointLength1, pointLength2));
};

export const handleFloat = {
    /**
     * 加法
     * @param {*} num1 
     * @param {*} num2 
     */
    add(num1, num2) {
        const pow = getPointPow(num1, num2);
        
        return (num1 * pow + num2 * pow) / pow;
    },
    /**
     * 减法
     * @param {*} num1 
     * @param {*} num2 
     */
    subtract(num1, num2) {
        const pow = getPointPow(num1, num2);
        return (num1 * pow - num2 * pow) / pow;
    },
    /**
     * 乘法
     * @param {*} num1 
     * @param {*} num2 
     */
    multiple(num1, num2) {
        const pow = getPointPow(num1, num2);
        return ((num1 * pow) * (num2 * pow)) / (pow * pow);
    },
    /**
     * 除法
     * @param {*} num1 
     * @param {*} num2 
     */
    divide(num1, num2) {
        const pow = getPointPow(num1, num2);
        return ((num1 * pow) / (num2 * pow));
    }
};
