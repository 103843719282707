/*
 * @Author: lina
 * @Email: lina5@guahao.com
 * @Date: 2020-03-03 10:28:43
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-09-23 10:32:08
 * @Description: 采购数据统计
 * @Route: Route
 */

const PurTotal = () => import(/* webpackChunkName: "purchase-list" */ '@/pages/tender/quantity-statistics/drug/data-list');
const DrugFill = () => import(/* webpackChunkName: "quantity-fill" */ '@/pages/trade/quantity-manage/drug/fill');
const HosPurStatus = () => import(/* webpackChunkName: "purchase-list" */ '@/pages/tender/quantity-statistics/components/drug/hospital-purchase-status');

export default [
    {
        path: 'drug/list',
        component: PurTotal,
        meta: {
            title: '采购需求量数据统计',
            flag: 'quantity'
        }
    },
    {
        path: 'drug/list/fill',
        component: DrugFill,
        meta: {
            title: '采购填报',
            flag: 'quantity'
        }
    },
    {
        path: 'drug/hospital-purchase-status',
        component: HosPurStatus,
        meta: {
            title: '采购需求量填报进度跟踪',
            flag: 'quantity'
        }
    }
];
