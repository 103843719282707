/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-12-02 14:31:52
 * @Last Modified by: guoxy
 * @Last Modified time: 2021-04-01 16:54:39
 * @Description: 协议量分配审核-耗材
 */

import View from '@/components/view';

import County from './county';
import Cities from './cities';
import Province from './province';

export default [
    {
        path: 'consumable',
        component: View,
        children: [
            ...County,
            ...Cities,
            ...Province
        ]
    }
];
