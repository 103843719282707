/*
 * @Author: yanchunl
 * @Email: yanchunl@hxmec.com
 * @Date: 2021-07-05 11:39:06
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-07-05 13:54:19
 * @Description: 报量目录
 * @Route: Route
 */
import View from '@/components/view';

const CatalogProduct = () => import(/* webpackChunkName: "catalog-product-list" */ '@/pages/report/catalog-manage/catalog-product-list');
const List = () => import(/* webpackChunkName: "catalogue-list" */ '@/pages/report/catalog-manage/catalogue-list');

export default [
    {
        path: 'catalog-manage',
        meta: {
            title: '报量目录'
        },
        component: View,
        children: [
            {
                path: 'catalogue-list',
                component: List,
                meta: {
                    title: '目录管理'
                }
            },
            {
                path: 'catalog-product-list',
                component: CatalogProduct,
                meta: {
                    title: '目录产品管理'
                }
            }
        ]
        
    }
];
