/*
 * @author 张志成
 * @description mutation常量管理
 */

// 用户信息
export const SET_USERINFO = 'SET_USERINFO';
// 清除用户信息
export const CLEAR_USERINFO = 'CLEAR_USERINFO';
// 设置菜单id
export const SET_MENUID = 'SET_MENUID';
// 获取应用菜单
export const GET_APPLY_MENU = 'GET_APPLY_MENU';
// 设置页签数据
export const SET_PAGE_TAGS = 'SET_PAGE_TAGS';
// 删除页签数据
export const DELETE_PAGE_TAGS = 'DELETE_PAGE_TAGS';
// 清空菜单标签
export const CLEAR_PAGE_TAGS = 'CLEAR_PAGE_TAGS';
// 设置面包屑
export const SET_BREADCRUMB_DATA = 'SET_BREADCRUMB_DATA';
// 是否内嵌统一门户标志
export const SET_ISIMPLANT_FLAG = 'SET_ISIMPLANT_FLAG';
// 获取地区
export const GET_REGION_LIST = 'GET_REGION_LIST';
// 获取带量采购项目
export const GET_QUANTITY_LIST = 'GET_QUANTITY_LIST';
// 保存用户模块数量
export const SAVE_USER_MODULE_NUM = 'SAVE_USER_MODULE_NUM';
