/*
 * @Author: yanchunl
 * @Email: yanchunl@hxmec.com
 * @Date: 2021-08-19 20:18:42
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-08-19 20:26:22
 * @Description: 挂网价格填报
 * @Route: Route
 */
import View from '@/components/view';


const List = () => import(/* webpackChunkName: "catalogue-list" */ '@/pages/report/price-fill/fill-list');

export default [
    {
        path: 'price-fill',
        meta: {
            title: '挂网价格填报'
        },
        component: View,
        children: [
            {
                path: 'fill-list',
                component: List,
                meta: {
                    title: '挂网价格填报'
                }
            }
        ]
        
    }
];
