/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-09-11 16:42:44
* @Description: 专家信息相关路由
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/mechanism-code-mangage/expert-information/list');
const Detail = () => import(/* webpackChunkName: "detail" */ '@/pages/bdc/mechanism-code-mangage/expert-information/detail');

export default [
    {
        path: 'export-information/list',
        component: List,
        meta: {
            title: '专家信息'
        }
    },
    {
        path: 'export-information/list/detail',
        component: Detail,
        meta: {
            title: '专家信息详情',
            display: 'none'
        }
    }
];
