/*
 * @Author: yuanxf
 * @Email: yuanxf@hxmec.com
 * @Date: 2024-10-23 13:59:06
 * @Last Modified by: yuanxf
 * @Last Modified time: 2024-10-23 13:59:06
 * @Description: 联采办采购数据汇总
 * @Route: Route
 */
import View from '@/components/view';

const List = () => import(/* webpackChunkName: "catalogue-list" */ '@/pages/report/procurement-Joint-data-summary/list');

export default [
    {
        path: 'procurement-Joint-data-summary',
        meta: {
            title: '采购数据汇总(联采办)'
        },
        component: View,
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '采购数据汇总(联采办)'
                }
            }
        ]
        
    }
];
