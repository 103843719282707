/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-10-29 16:32:42
 * @Last Modified by: housy
 * @Last Modified time: 2020-04-10 15:17:06
 * @Description: 资质项配置/企业资质项配置
 */
const List = () => import(/* webpackChunkName: "enterprise-list" */ '@/pages/bdc/qualify-config/enterprise/list');
const Detail = () => import(/* webpackChunkName: "enterprise-detail" */ '@/pages/bdc/qualify-config/enterprise/detail');

export default [
    {
        path: 'enterprise/list',
        component: List,
        meta: {
            title: '企业资质项配置'
        }
    },
    {
        path: 'enterprise/list/read',
        component: Detail,
        meta: {
            title: '查看企业资质项',
            display: 'none'
        }
    },
    {
        path: 'enterprise/list/add',
        component: Detail,
        meta: {
            title: '新增企业资质项',
            display: 'none'
        }
    },
    {
        path: 'enterprise/list/edit',
        component: Detail,
        meta: {
            title: '编辑企业资质项',
            display: 'none'
        }
    }

];