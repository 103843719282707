/*
 * @Author: yanghy5
 * @Email: yanghy5@guahao.com
 * @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-09-11 16:41:32
 * @Description: 药品和医用耗材编码同步管理/药品编码同步管理相关路由
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/mechanism-code-mangage/drug-consumable-code-manage/drug-code-manage/list');
const Detail = () => import(/* webpackChunkName: "detail" */ '@/pages/bdc/mechanism-code-mangage/drug-consumable-code-manage/drug-code-manage/detail');

export default [
    {
        path: 'drug-code/list',
        component: List,
        meta: {
            title: '药品编码查询'
        }
    },
    {
        path: 'drug-code/list/detail',
        component: Detail,
        meta: {
            title: '药品编码查询详情',
            display: 'none'
        }
    }
];
