/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-12-13 10:16:10
* @Description: 生产、配送企业信息同步管理/配送企业相关路由
*/

import View from '@/components/view';

const DrugList = () => import(/* webpackChunkName: "drug-sort-code-list" */ '@/pages/bdc/mechanism-code-mangage/sort-code/drug-sort-code/list');
const ConsumableDetail = () => import(/* webpackChunkName: "consumable-sort-code-list" */ '@/pages/bdc/mechanism-code-mangage/sort-code/consumable-sort-code/list');


export default [
    {
        path: 'sort-code',
        component: View,
        meta: {
            title: '分类与代码'
        },
        children: [
            {
                path: 'drug-sort-code/list',
                component: DrugList,
                meta: {
                    title: '药品分类与代码'
                }
            },
            {
                path: 'consumable-sort-code/list',
                component: ConsumableDetail,
                meta: {
                    title: '医用耗材分类与代码'
                }
            }
        ]
    }

];
