import View from '@/components/view';
import DrugCode from './drug-code-manage';
import ConsumableCode from './consumable-code-manage';

export default [
    {
        path: 'drug-consumable-code-manage',
        component: View,
        meta: {
            title: '药品和医用耗材编码查询'
        },
        children: [
            ...DrugCode,
            ...ConsumableCode
        ]
    }
];
