import devConfig from './config.dev';
import prodConfig from './config.prod';
import preConfig from './config.pre';
import testConfig from './config.test';
import baseConfig from './config.base';

const env = process.env.NODE_ENV;

/* eslint-disable */
const config = env === 'development' ? devConfig : (env === 'production' ? prodConfig : (env === 'pre' ? preConfig : testConfig));

export default Object.assign(baseConfig, config);
