/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2021-03-15 10:39:30
 * @Last Modified by: zhujial
 * @Last Modified time: 2024-04-15 15:46:36
 * @Description: 微前端
 */
import View from '@/components/view';
// 基础数据
import Bdc from './bdc';
// 管理端
import Tender from './tender';
// 医疗机构
import Trade from './trade';
// 约定量管理
import report from './report';
import allocation from './allocation'; // 分量

const FillImportPreview = () => import(/* webpackChunkName: "quantity-fill-import-preview" */ '@/pages/trade/quantity-manage/drug/import-preview');
const hisFill2ImportPreview = () => import(/* webpackChunkName: "quantity-fill-import-preview" */ '@/pages/report/historical-data-manage/varieties/import-preview');

const routes = [

    // ...Bdc,
    // ...Tender,
    // ...Trade,
    // ...report

    {
        path: '/report',
        component: View,
        meta: {
            title: '报量系统'
        },
        children: [
            ...Bdc[0].children,
            ...Tender[0].children,
            ...Trade[0].children,
            ...report[0].children,
            ...allocation
        ]
    },
    {
        path: '/report/quantity-manage/fill/import-preview',
        component: FillImportPreview,
        meta: {
            title: '导入预览',
            display: 'none',
            noMenu: true
        }
    },
    {
        path: '/report/historical-data-manage/historical-fill/import-preview',
        component: hisFill2ImportPreview,
        meta: {
            title: '导入预览',
            display: 'none',
            noMenu: true
        }
    }
];

export default routes;
