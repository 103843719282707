/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-10-29 16:35:47
 * @Last Modified by: housy
 * @Last Modified time: 2019-10-29 16:51:41
 * @Description: 资质项配置总路由
 */
import View from '@/components/view';
import Enterprise from './enterprise';
import Product from './product';

export default [
    {
        path: 'qualify-config',
        component: View,
        meta: {
            title: '资质项配置'
        },
        children: [
            ...Enterprise,
            ...Product
        ]
    }
];
