/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-10-29
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-08-22 15:01:52
 * @Description: 国家项目报量管理路由
 */
import View from '@/components/view';
import DRUGROUTES from './drug';
import CONSUMABLEROUTES from './consumable';

const DrugCheckout = () => import(/* webpackChunkName: "Checkout" */ '@/pages/tender/province-report-management/drug/checkout');
const ConsumableCheckout = () => import(/* webpackChunkName: "Checkout" */ '@/pages/tender/province-report-management/consumable/checkout');

export default [
    {
        path: 'province-project',
        component: View,
        meta: {
            title: '报量项目管理'
        },
        children: [
            ...DRUGROUTES,
            ...CONSUMABLEROUTES,
            {
                path: 'list/drug/checkout',
                component: DrugCheckout,
                meta: {
                    title: '查看'
                }
            },
            {
                path: 'list/consumable/checkout',
                component: ConsumableCheckout,
                meta: {
                    title: '查看'
                }
            }
        ]
    }
];
