/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-12-02 14:43:35
 * @Last Modified by: guoxy
 * @Last Modified time: 2021-04-01 14:07:12
 * @Description: 管理端-地市协议量分配审核
 */

const List = () => import(/* webpackChunkName: "allocation-audit-county-list" */ '@/pages/tender/allocation-audit/consumable/county/list');

export default [
    {
        path: 'county/list',
        component: List,
        meta: {
            title: '区县协议量分配审核'
        } 
    }
];
