/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-08-16 11:25:10
* @Description: 生产、配送企业信息同步管理/配送企业相关路由
*/

import View from '@/components/view';


import ExpressOrg from './express-org';
import ProductOrg from './product-org';

export default [
    {
        path: 'product-express-information-manage',
        component: View,
        meta: {
            title: '生产、配送企业信息查询'
        },
        children: [
            ...ProductOrg,
            ...ExpressOrg
        ]
    }

];
