/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-12-02 14:34:21
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-11-22 21:57:24
 * @Description: 医院端-协议量分配
 */

const Consumable = () => import(/* webpackChunkName: "list" */ '@/pages/tender/allocation-manage/product-org/consumable/list');
const Info = () => import('@/pages/tender/allocation-manage/product-org/consumable/info');

export default [
    {
        path: 'allocation-manage/product-org/consumable/list',
        component: Consumable,
        meta: {
            title: '协议量分配'
        }
    },
    {
        path: 'allocation-manage/product-org/consumable/list/info',
        component: Info,
        meta: {
            title: '协议量分配详情'
        }
    }
];
