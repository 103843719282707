

/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: zhangzczc
 * @Last Modified time: 2019-07-12 13:55:311
* @Description: 数据同步/政策文件数据管理
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/public-service/policy-data-manage/list');
const Add = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/public-service/policy-data-manage/add');
const Edit = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/public-service/policy-data-manage/edit');
const Read = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/public-service/policy-data-manage/read');

export default [
    {
        path: 'policy-data-manage/list',
        component: List,
        meta: {
            title: '政策文件数据管理'
        }
    },
    {
        path: 'policy-data-manage/list/add',
        component: Add,
        meta: {
            title: '新增政策文件',
            display: 'none'
        }
    },
    {
        path: 'policy-data-manage/list/edit',
        component: Edit,
        meta: {
            title: '编辑政策文件',
            display: 'none'
        }
    },
    {
        path: 'policy-data-manage/list/read',
        component: Read,
        meta: {
            title: '查看政策文件',
            display: 'none'
        }
    }
];
