/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-04 16:54:53
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-06 16:50:48
 * @Description: 采购计划总路由
 */

const List = () => import(/* webpackChunkName: "procurement-plan-list" */ '@/pages/bdc/procurement-plan/list');
const ReportedPurchaseAmount = () => import(/* webpackChunkName: "reported-purchase-amount-list" */ '@/pages/bdc/procurement-plan/reported-purchase-amount');
const HasBeenReported = () => import(/* webpackChunkName: "has-been-reported-list" */ '@/pages/bdc/procurement-plan/has-been-reported');

export default [
    {
        path: 'procurement-plan/list',
        component: List,
        meta: {
            title: '采购计划'
        }
    },
    {
        path: 'procurement-plan/list/reported-purchase-amount',
        component: ReportedPurchaseAmount,
        meta: {
            title: '上报采购量',
            display: 'none'
        }
    },
    {
        path: 'procurement-plan/list/has-been-reported',
        component: HasBeenReported,
        meta: {
            title: '已上报查看',
            display: 'none'
        }
    }
];
