/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: zhangzczc
 * @Last Modified time: 2019-07-12 11:47:499
* @Description: 生产、配送企业信息同步管理/配送企业相关路由
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/mechanism-code-mangage/product-express-information-manage/product-org/list');
const Detail = () => import(/* webpackChunkName: "detail" */ '@/pages/bdc/mechanism-code-mangage/product-express-information-manage/product-org/detail');

export default [
    {
        path: 'product/list',
        component: List,
        meta: {
            title: '生产企业信息查询'
        }
    },
    {
        path: 'product/list/detail',
        component: Detail,
        meta: {
            title: '生产企业信息查询详情',
            display: 'none'
        }
    }
];
