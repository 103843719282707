/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-12-02 14:43:35
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-11-26 10:37:19
 * @Description: 管理端-协议量分配管理
 */


import View from '@/components/view';

const ResultsMaintenance = () => import(/* webpackChunkName: "results-maintenance-list" */ '@/pages/tender/allocation-manage/regulator-org/consumable/results-maintenance/list');
const ResultsMaintenanceHb = () => import(/* webpackChunkName: "results-maintenance-list-hb" */ '@/pages/tender/allocation-manage/regulator-org/consumable/results-maintenance/list-hb');
const InstitutionalDemand = () => import(/* webpackChunkName: "institutional-demand-list" */ '@/pages/tender/allocation-manage/regulator-org/consumable/institutional-demand/list');
const InstitutionalDemandHb = () => import(/* webpackChunkName: "institutional-demand-list-hb" */ '@/pages/tender/allocation-manage/regulator-org/consumable/institutional-demand/list-hb');
const StatisticsQuery = () => import(/* webpackChunkName: "statistics-query-list" */ '@/pages/tender/allocation-manage/regulator-org/consumable/statistics-query/list');
const ParamsSet = () => import(/* webpackChunkName: "statistics-query-list" */ '@/pages/tender/allocation-manage/regulator-org/consumable/params-set/list');

export default [
    {
        path: 'allocation-manage/regulator-org',
        component: View,
        meta: {
            title: '协议量分配管理'
        },
        children: [
            {
                path: 'consumable/results-maintenance/list',
                component: ResultsMaintenance,
                meta: {
                    title: '集采结果维护'
                }
            },
            {
                path: 'consumable/results-maintenance/list-hb',
                component: ResultsMaintenanceHb,
                meta: {
                    title: '集采结果维护'
                }
            },
            {
                path: 'consumable/institutional-demand/list',
                component: InstitutionalDemand,
                meta: {
                    title: '医疗机构需求量'
                }
            },
            {
                path: 'consumable/institutional-demand/list-hb',
                component: InstitutionalDemandHb,
                meta: {
                    title: '医疗机构需求量'
                }
            },
            {
                path: 'consumable/statistics-query/list',
                component: StatisticsQuery,
                meta: {
                    title: '协议量统计查询'
                }
            },
            {
                path: 'consumable/params-set/list',
                component: ParamsSet,
                meta: {
                    title: '协议量参数配置'
                }
            }
        ]    
    }
];
