/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-12-02 14:43:35
 * @Last Modified by: guoxy
 * @Last Modified time: 2021-04-08 10:33:32
 * @Description: 管理端-省级协议量分配审核
 */

import View from '@/components/view';

const List = () => import(/* webpackChunkName: "allocation-audit-consumable-cities" */ '@/pages/tender/allocation-audit/consumable/province/list');
const CountyDetail = () => import(/* webpackChunkName: "allocation-audit-consumable-cities-county" */ '@/pages/tender/allocation-audit/consumable/province/county-detail');
const CityDetail = () => import(/* webpackChunkName: "allocation-audit-consumable-cities-city" */ '@/pages/tender/allocation-audit/consumable/province/city-detail');
const ProvinceDetail = () => import(/* webpackChunkName: "allocation-audit-consumable-cities-city" */ '@/pages/tender/allocation-audit/consumable/province/province-detail');

export default [
    {
        path: 'province',
        component: View,
        meta: {
            title: '省级协议量分配审核'
        },
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '省级协议量分配审核'
                }
            },
            {
                path: 'list/city-detail',
                component: CityDetail,
                meta: {
                    title: '地市详情'
                }
            },
            {
                path: 'list/city-detail/county-detail',
                component: CountyDetail,
                meta: {
                    title: '区县详情'
                }
            },
            {
                path: 'list/province-detail',
                component: ProvinceDetail,
                meta: {
                    title: '省直详情'
                }
            }
        ]    
    }
];
