/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: zhangzczc
 * @Last Modified time: 2019-07-12 11:41:166
* @Description: 药品和医用耗材采购单位同步管理相关路由
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/mechanism-code-mangage/medical-information-manage/list');
const Detail = () => import(/* webpackChunkName: "detail" */ '@/pages/bdc/mechanism-code-mangage/medical-information-manage/detail');

export default [
    {
        path: 'medical-information-manage/list',
        component: List,
        meta: {
            title: ' 医疗机构信息查询'
        }
    },
    {
        path: 'medical-information-manage/list/detail',
        component: Detail,
        meta: {
            title: '医疗机构信息查询详情',
            display: 'none'
        }
    }
];
