/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 10:36
 * @route
 * @description 报量项目管理
*/

const List = () => import(/* webpackChunkName: "quantity-list" */ '@/pages/tender/quantity-manage/list');
const ProvinceList = () => import(/* webpackChunkName: "province-list" */ '@/pages/tender/quantity-manage/province-list');
const VolumnEdit = () => import(/* webpackChunkName: "volumn-edit" */ '@/pages/tender/quantity-manage/volume-edit');
const VolumnDetail = () => import(/* webpackChunkName: "volumn-detail" */ '@/pages/tender/quantity-manage/volume-detail');

export default [
    {
        path: 'quantity-manage/list',
        component: List,
        meta: {
            title: '国家项目报量管理'
        }
    },
    {
        path: 'quantity-manage/province-list',
        component: ProvinceList,
        meta: {
            title: '报量项目管理'
        }
    },
    {
        path: 'quantity-manage/province-list/volume-edit',
        component: VolumnEdit,
        meta: {
            title: '报量配置'
        }
    },
    {
        path: 'quantity-manage/province-list/volume-detail',
        component: VolumnDetail,
        meta: {
            title: '查看报量'
        }
    }
];
