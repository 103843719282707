/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 10:36
 * @route
 * @description 报量项目管理
*/

const ProvinceList = () => import(/* webpackChunkName: "province-list" */ '@/pages/trade/quantity-manage/province-list');
const Fill = () => import(/* webpackChunkName: "quantity-fill" */ '@/pages/trade/quantity-manage/drug/index');
const ReportData = () => import(/* webpackChunkName: "report-data" */ '@/pages/trade/quantity-manage/report-data');
const ReportDataDetail = () => import(/* webpackChunkName: "report-data-detail" */ '@/pages/trade/quantity-manage/report-data-detail');
const ReportDataDetailAmount = () => import(/* webpackChunkName: "report-data-detail-amount" */ '@/pages/trade/quantity-manage/report-data-detail/amount');
const ConsumableFill = () => import(/* webpackChunkName: "consumable-quantity-fill" */ '@/pages/trade/quantity-manage/consumable/index');
const Consumable = () => import(/* webpackChunkName: "list" */ '@/pages/tender/allocation-manage/product-org/consumable/list');
const ConsumableInfo = () => import(/* webpackChunkName: "list" */ '@/pages/tender/allocation-manage/product-org/consumable/info');
const sdConsumable = () => import(/* webpackChunkName: "list" */ '@/pages/tender/allocation-manage/product-org/consumable/sd-list');
const ViewSelected = () => import(/* webpackChunkName: "list" */ '@/pages/trade/quantity-manage/consumable/view-selected');
export default [
    {
        path: 'quantity-manage/product-org/province-list',
        component: ProvinceList,
        meta: {
            title: '报量项目管理'
        }
    },
    {
        path: 'quantity-manage/province-list/drug/fill',
        component: Fill,
        meta: {
            title: '采购数据填报',
            display: 'none'
        }
    },
    {
        path: 'quantity-manage/report-data',
        component: ReportData,
        meta: {
            title: '报送数据'
        }
    },
    {
        path: 'quantity-manage/report-data-detail',
        component: ReportDataDetail,
        meta: {
            title: '报送数据'
        }
    },
    {
        path: 'quantity-manage/report-data-detail/amount',
        component: ReportDataDetailAmount,
        meta: {
            title: '报量数据'
        }
    },
    {
        path: 'quantity-manage/province-list/consumable/fill',
        component: ConsumableFill,
        meta: {
            title: '采购数据填报',
            display: 'none'
        }
    },
    {
        path: 'quantity-manage/province-list/consumable/allocation',
        component: Consumable,
        meta: {
            title: '协议量分配',
            display: 'none'
        }
    },
    {
        path: 'quantity-manage/province-list/consumable/allocation/info',
        component: ConsumableInfo,
        meta: {
            title: '协议量分配',
            display: 'none'
        }
    },
    {
        path: 'quantity-manage/province-list/consumable/sd-allocation',
        component: sdConsumable,
        meta: {
            title: '协议量分配'
            // display: 'none'
        }
    },
    {
        path: 'quantity-manage/province-list/consumable/view-selected',
        component: ViewSelected,
        meta: {
            title: '选择',
            display: 'none'
        }
    }
];
