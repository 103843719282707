/*
 * @Author: shandan
 * @Email: shandan@guahao.com
 * @Date: 2021-03-29 15:26:08
 * @Last Modified by: shandan
 * @Last Modified time: 2021-03-29 15:27:10
 * @Description: 耗材-采购数据统计
 */
const volumeAgreement = () => import(/* webpackChunkName: "statisticsList" */ '@/pages/tender/volume-agreement/list'); 

const routes = [
    {
        path: 'volume-agreement/list',
        component: volumeAgreement,
        meta: {
            title: '协议量明细查看'
        }
    }
];

export default routes;