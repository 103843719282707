/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:19
 * @route
 * @description 我的产品库
*/

import View from '@/components/view';
import HomePage from './home-page'; // 项目首页及项目工作台
import NationalProjectReportManagement from './national-project-report-management'; // 国家项目报量管理
import NationalProjectExecutionManagement from './national-project-execution-management'; // 国家项目报量管理
import FillPurchaseQuality from './fill-purchase-quality';
import QuantityManage from './quantity-manage';
import QuantityStatistics from './quantity-statistics';
import ProvinceReport from './province-report-management'; // 省级报量项目管理
import AllocationManage from './allocation-manage'; // 拆量
import volumeAgreement from './volume-agreement'; // 协议量明细查看
import AllocationAudit from './allocation-audit'; // 拆量

export default [
    {
        path: '/report',
        component: View,
        meta: {
            title: '招采系统'
        },
        children: [
            ...HomePage,
            ...NationalProjectReportManagement,
            ...NationalProjectExecutionManagement,
            ...FillPurchaseQuality,
            ...QuantityManage,
            ...QuantityStatistics,
            ...ProvinceReport,
            ...AllocationManage,
            ...volumeAgreement,
            ...AllocationAudit
        ]
    }
];
