/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-12-02 14:31:52
 * @Last Modified by: guoxy
 * @Last Modified time: 2021-04-09 12:27:46
 * @Description: 协议量分配审核
 */

import View from '@/components/view';
import Index from '@/pages/tender/index';
import Consumable from './consumable';

export default [
    {
        path: 'allocation-audit',
        component: View,
        children: [
            ...Consumable
        ]    
    },
    {
        path: 'allocation-index',
        component: Index
    }
];
