/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-09-11 16:50:30
* @Description: 基本业务服务/协议管理相关路由
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/public-service/agreement/list');
const Read = () => import(/* webpackChunkName: "read" */ '@/pages/bdc/public-service/agreement/read');
const Edit = () => import(/* webpackChunkName: "edit" */ '@/pages/bdc/public-service/agreement/edit');
const Add = () => import(/* webpackChunkName: "add" */ '@/pages/bdc/public-service/agreement/add');

export default [
    {
        path: 'agreement/list',
        component: List,
        meta: {
            title: '协议管理'
        }
    },
    {
        path: 'agreement/list/read',
        component: Read,
        meta: {
            title: '查看协议模板',
            display: 'none'
        }
    },
    {
        path: 'agreement/list/add',
        component: Add,
        meta: {
            title: '新增协议模板',
            display: 'none'
        }
    },
    {
        path: 'agreement/list/edit',
        component: Edit,
        meta: {
            title: '编辑协议模板',
            display: 'none'
        }
    }

];
