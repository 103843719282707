/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: caoyp
 * @Last Modified time: 2021-04-23 10:07:34
* @Description: 基础数据页面总路由
*/
import View from '@/components/view';
import MechanismCode from './mechanism-code-mangage';
import ProductPolicyAttr from './product-policy-attr-manage';
import PublicService from './public-service';
import PriceQuery from './price-query';
import productBlacklistManage from './product-blacklist-manage';
import procurementPlan from './procurement-plan';
import QualifyConfig from './qualify-config';
import Medrelationship from './med-relationship';

export default [
    {
        path: '/report',
        component: View,
        meta: {
            title: '基础数据系统'
        },
        children: [
            ...MechanismCode,
            ...ProductPolicyAttr,
            ...PublicService,
            ...PriceQuery,
            ...productBlacklistManage,
            ...procurementPlan,
            ...QualifyConfig,
            ...Medrelationship
        ]
    }
];
