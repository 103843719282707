/*
 * @Author: zhujial
 * @Email: zhujial@hxmec.com
 * @Date: 2023-05-23 11:08:06
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-06-25 10:00:38
 */
import View from '@/components/view';

// 基础数据管理
const ReportDetails = () => import(/* webpackChunkName: "report-details-data-manage" */ '@/pages/allocation/manage-org/basic-data-manage/report-details'); // 报量明细数据管理
const UpscChosen = () => import(/* webpackChunkName: "upsc-chosen-data-manage" */ '@/pages/allocation/manage-org/basic-data-manage/upsc-chosen'); // 联采中选数据管理

// 协议分量管理
const AllocationAudit = () => import(/* webpackChunkName: "allocation-audit" */ '@/pages/allocation/manage-org/allocation-manage/allocation-audit'); // 约定采购量分配审核
const HospPrepurcDetails = () => import(/* webpackChunkName: "hosp-prepurc-details" */ '@/pages/allocation/manage-org/allocation-manage/hosp-prepurc-details'); // 医院预采购明细
const AllocationPlateAudit = () => import(/* webpackChunkName: "allocation-plate-audit" */ '@/pages/allocation/manage-org/allocation-manage/allocation-plate-audit'); // 约定采购量分配平台审核
const AllocationProgressQuery = () => import(/* webpackChunkName: "allocation-progress-query" */ '@/pages/allocation/manage-org/allocation-manage/allocation-progress-query'); // 约定采购量分配进度查询
const AllocationProgressQueryUpsc = () => import(/* webpackChunkName: "allocation-progress-query-upsc" */ '@/pages/allocation/manage-org/allocation-manage/allocation-progress-query-upsc'); // 约定采购量分配进度查询(联采办)


// 协议分量统计
const AllocationStatisticsUpsc = () => import(/* webpackChunkName: "allocation-statistics-upsc" */ '@/pages/allocation/manage-org/allocation-statistics/upsc-index'); // 分量统计（联采办）
const AllocationStatisticsManage = () => import(/* webpackChunkName: "allocation-statistics-manage" */ '@/pages/allocation/manage-org/allocation-statistics/manage-index'); // 协议量分配统计（医保局）

// 约定量统计
const AgreedProcurement = () => import(/* webpackChunkName: "agreed-procurement" */ '@/pages/allocation/manage-org/agreed-procurement/list'); // 约定量统计

export default [
    {
        path: 'manage-org',
        component: View,
        meta: {
            title: '管理单位'
        },
        children: [
            {
                path: 'data-manage',
                component: View,
                meta: {
                    title: '基础数据管理'
                },
                children: [
                    {
                        path: 'report-details',
                        component: ReportDetails,
                        meta: {
                            title: '报量明细数据管理'
                        }
                    },
                    {
                        path: 'upsc-chosen',
                        component: UpscChosen,
                        meta: {
                            title: '联采中选数据管理'
                        }
                    }
                ]
            },
            {
                path: 'allocation-manage',
                component: View,
                meta: {
                    title: '协议分量管理'
                },
                children: [
                    {
                        path: 'audit',
                        component: AllocationAudit,
                        meta: {
                            title: '约定采购量分配审核'
                        }
                    },
                    {
                        path: 'plate-audit',
                        component: AllocationPlateAudit,
                        meta: {
                            title: '约定采购量分配平台审核'
                        }
                    },
                    {
                        path: 'progress-query',
                        component: AllocationProgressQuery,
                        meta: {
                            title: '约定采购量分配进度查询'
                        }
                    },
                    {
                        path: 'progress-query-upsc',
                        component: AllocationProgressQueryUpsc,
                        meta: {
                            title: '约定采购量分配进度查询'
                        }
                    },
                    {
                        path: 'hosp-prepurc-details',
                        component: HospPrepurcDetails,
                        meta: {
                            title: '医院预采购明细'
                        }
                    }
                ]
            },
            {
                path: 'allocation-statistics',
                component: View,
                meta: {
                    title: '协议分量统计'
                },
                children: [
                    {
                        path: 'upsc-index',
                        component: AllocationStatisticsUpsc,
                        meta: {
                            title: '分量统计'
                        }
                    },
                    {
                        path: 'manage-index',
                        component: AllocationStatisticsManage,
                        meta: {
                            title: '协议量分配统计'
                        }
                    }
                ]
            },
            {
                path: 'agreed-procurement',
                component: View,
                meta: {
                    title: '约定采购量统计'
                },
                children: [
                    {
                        path: 'list',
                        component: AgreedProcurement,
                        meta: {
                            title: '约定采购量分配查询'
                        }
                    }
                ]
            }
        ]
    }
];
