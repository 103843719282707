/*
 * @Author: zhujial
 * @Email: zhujial@hxmec.com
 * @Date: 2023-05-22 17:30:25
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-06-01 17:47:54
 * @Description: 分量系统
 * @Route: 
 */
import View from '@/components/view';
import Manage from './manage-org'; // 管理单位
import Hospital from './hosp-org';// 医疗机构

const projectList = () => import(/* webpackChunkName: "allocation-project-manage-list" */ '@/pages/allocation/project-manage/index'); // 分量项目列表
const projectEdit = () => import(/* webpackChunkName: "allocation-project-manage-edit" */ '@/pages/allocation/project-manage/edit'); // 分量项目列表/配置管理

export default [
    {
        path: 'allocation',
        component: View,
        meta: {
            title: '分量系统'
        },
        children: [
            ...Manage,
            ...Hospital,
            {
                path: 'project-manage',
                component: projectList,
                meta: {
                    title: '分量项目列表'
                }
            },
            {
                path: 'project-manage/edit',
                component: projectEdit,
                meta: {
                    title: '配置管理'
                }
            }
        ]
    }
];
