import slotPreview from './slot-preview';

const install = (Vue, options) => {
    const SlotViewer = Vue.extend(slotPreview);
    const TargetPdf = new SlotViewer({
        propsData: options
    });
    
    TargetPdf.vm = TargetPdf.$mount();
    const domPdf = TargetPdf.vm.$el;
    document.body.appendChild(domPdf); // 将dom插入body

    Vue.prototype.$SlotPreview = info => {
        TargetPdf.vm.handleVisible(info);
    };
};

export default { install };