/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-04 14:12:25
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-04 14:12:46
 * @Description: 价格查询页面总路由
 */

import View from '@/components/view';
import NapDrugPriceQuery from './nap-drug-price-query';
import NegotiateDrugQuery from './negotiate-drug-query';
import DrugConsumablePrice from './drug-consumable-price-query';
import DrugFixedPrice from './drug-fixed-price-manage';

export default [
    {
        path: 'price-query',
        component: View,
        meta: {
            title: '价格查询'
        },
        children: [
            ...DrugConsumablePrice,
            ...DrugFixedPrice,
            ...NapDrugPriceQuery,
            ...NegotiateDrugQuery
        ]
    }
];
