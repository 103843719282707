/*
 * @Author: guoxy1
 * @Email: guoxy1@guahao.com
 * @Date: 2019-11-04 11:16:32
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-04 19:19:35
 * @Description: 价格查询/药品限价管理/国家限价查询
 */

const List = () => import(/* webpackChunkName: "national-fixed-price-list" */ '@/pages/bdc/price-query/drug-fixed-price-manage/national-fixed-price/list');
export default [
    {
        path: 'national-fixed-price/list',
        component: List,
        meta: {
            title: '国家限价查询'
        }
    }
];
