

/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-04 19:20:12
* @Description: 价格查询/药品和医用耗材价格查询相关路由
*/

import View from '@/components/view';
import Consumable from './consumable';
import Drug from './drug';

export default [
    {
        path: 'drug-consumable-price-query',
        component: View,
        meta: {
            title: '药品和医用耗材价格查询'
        },
        children: [
            ...Drug,
            ...Consumable
        ]
    }
];
