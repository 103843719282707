/*
 * @Author: yanchunl
 * @Email: yanchunl@hxmec.com
 * @Date: 2021-08-19 20:18:42
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-09-02 10:10:37
 * @Description: 填报数据统计
 * @Route: Route
 */
import View from '@/components/view';


const List = () => import(/* webpackChunkName: "catalogue-list" */ '@/pages/report/fill-statistics/list');

export default [
    {
        path: 'fill-statistics',
        meta: {
            title: '填报数据统计'
        },
        component: View,
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '填报数据统计'
                }
            }
        ]
        
    }
];
