import View from '@/components/view';
// 工作台
import Home from './home/index';
// 国家项目报量管理
import QuantityManage from './quantity-manage';

const routes = [
    {
        path: '/report',
        component: View,
        meta: {
            title: '交易结算系统'
        },
        children: [
            ...Home,
            ...QuantityManage
        ]
    }
];

export default routes;
