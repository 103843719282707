export default {
    uploadPath: '/code/hsaRpup/tps-rpup/web/comp/file/upload',
    // 上传
    // uploadPath: '/hsaRpup/tps-rpup/web/comp/file/upload',
    // baseUrl: '/code',
    baseUrl: 'https://apitcme.guahao-test.com/code',
    upmsSource: 'test',
    // 门户地址
    // adminPage: '//tcme-admin.guahao-test.com'
    adminPage: '//admin.bqex.com',
    vcodePath: 'https://apitcme.guahao-test.com/code'
};
