/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-12-02 14:31:52
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-12-02 14:32:15
 * @Description: 协议量分配总路由
 */

import Product from './product-org';
import Regulator from './regulator-org';

export default [
    ...Product,
    ...Regulator
];
