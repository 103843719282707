/*
 * @Author: yanghy5
 * @Email: yanghy5@guahao.com
 * @Date: 2020-02-19 15:35:48
 * @Last Modified by: caoyp
 * @Last Modified time: 2020-07-29 10:42:52
 * @Description: 采购数量填报
 * @Route: Route
 */
const List = () => import(/* webpackChunkName: "list" */ '@/pages/tender/fill-purchase-quality/drug/list');
const Fill = () => import(/* webpackChunkName: "fill" */ '@/pages/tender/fill-purchase-quality/drug/fill');
const detail = () => import(/* webpackChunkName: "fill" */ '@/pages/tender/fill-purchase-quality/drug/detail');
const edit = () => import(/* webpackChunkName: "fill" */ '@/pages/tender/fill-purchase-quality/drug/edit');
const ConsumableList = () => import(/* webpackChunkName: "consumable-list" */ '@/pages/tender/fill-purchase-quality/consumable/list');
const ConsumableFill = () => import(/* webpackChunkName: "consumable-list" */ '@/pages/tender/fill-purchase-quality/consumable/fill');
const ConsumableDetail = () => import(/* webpackChunkName: "consumable-list" */ '@/pages/tender/fill-purchase-quality/consumable/detail');
const ConsumableEdit = () => import(/* webpackChunkName: "consumable-list" */ '@/pages/tender/fill-purchase-quality/consumable/edit');

export default [
    {
        path: 'fill-purchase-quality/drug/list',
        component: List,
        meta: {
            title: '采购数据填报',
            flag: 'quantity'
        }
    },
    {
        path: 'fill-purchase-quality/drug/list/fill',
        component: Fill,
        meta: {
            title: '填写',
            flag: 'quantity'
        }
    },
    {
        path: 'fill-purchase-quality/drug/list/detail',
        component: detail,
        meta: {
            title: '详情',
            flag: 'quantity'
        }
    },
    {
        path: 'fill-purchase-quality/drug/list/edit',
        component: edit,
        meta: {
            title: '编辑',
            flag: 'quantity'
        }
    },
    {
        path: 'fill-purchase-quality/consumable/list',
        component: ConsumableList,
        meta: {
            title: '采购数据填报',
            flag: 'quantity'
        }
    },
    {
        path: 'fill-purchase-quality/consumable/list/fill',
        component: ConsumableFill,
        meta: {
            title: '填写',
            flag: 'quantity'
        }
    },
    {
        path: 'fill-purchase-quality/consumable/list/detail',
        component: ConsumableDetail,
        meta: {
            title: '详情',
            flag: 'quantity'
        }
    },
    {
        path: 'fill-purchase-quality/consumable/list/edit',
        component: ConsumableEdit,
        meta: {
            title: '编辑',
            flag: 'quantity'
        }
    }
];