/*
 * @Author: zhujl3
 * @Email: zhujl3@wedoctor.com
 * @Date: 2024-10-22 14:23:58
 * @Last Modified by: zhujl3
 * @Last Modified time: 2024-10-25 17:48:17
 * @Description: 审核上报
 */
import View from '@/components/view';

const AuditDataReport = () => import(/* webpackChunkName: "purchase-audit-data-report" */ '@/pages/report/purchase-data-report/audit-data-report');
const DataReport = () => import(/* webpackChunkName: "purchase-data-report" */ '@/pages/report/purchase-data-report/data-report');


export default [
    {
        path: 'purchase-data-report',
        meta: {
            title: '审核上报'
        },
        component: View,
        children: [
            {
                path: 'audit-data-report',
                component: AuditDataReport,
                meta: {
                    title: '采购需求审核数据上报'
                }
            },
            {
                path: 'data-report',
                component: DataReport,
                meta: {
                    title: '采购需求数据上报'
                }
            }
        ]
        
    }
];
