import View from '@/components/view';
import Agreement from './agreement';
import PublicInfo from './public-info';
import PolicyData from './policy-data-manage';

export default [
    {
        path: 'public-service',
        component: View,
        meta: {
            title: '基本业务服务'
        },
        children: [
            ...PolicyData,
            ...PublicInfo,
            ...Agreement
        ]
    }
];
