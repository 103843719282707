/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: zhangzczc
 * @Last Modified time: 2019-07-12 13:51:422
* @Description: 价格查询/药品和医用耗材价格查询/医用耗材价格查询相关路由
*/

const List = () => import(/* webpackChunkName: "drug-consumable-price-query-consumable-list" */ '@/pages/bdc/price-query/drug-consumable-price-query/consumable/list');
const Detail = () => import(/* webpackChunkName: "drug-consumable-price-query-consumable-detail" */ '@/pages/bdc/price-query/drug-consumable-price-query/consumable/detail');

export default [
    {
        path: 'consumable/list',
        component: List,
        meta: {
            title: '医用耗材价格查询'
        }
    },
    {
        path: 'consumable/list/detail',
        component: Detail,
        meta: {
            title: '医用耗材价格详情',
            display: 'none'
        }
    }
];
