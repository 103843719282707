/*
 * @Author: yanchunl
 * @Email: yanchunl@hxmec.com
 * @Date: 2021-06-29 15:37:30
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-07-05 11:33:46
 * @Description: 约定量管理
 * @Route: Route
 */
import View from '@/components/view';

const Appoint = () => import(/* webpackChunkName: "appoint-list" */ '@/pages/report/agree-quantity-manage/appoint-list');
const Agreed = () => import(/* webpackChunkName: "agreed-quantity-statistics" */ '@/pages/report/agree-quantity-manage/agreed-quantity-statistics');

export default [
    {
        path: 'agree-quantity-manage',
        meta: {
            title: '约定量管理'
        },
        component: View,
        children: [
            {
                path: 'appoint-list',
                component: Appoint,
                meta: {
                    title: '约定药量统计'
                }
            },
            {
                path: 'agreed-quantity-statistics',
                component: Agreed,
                meta: {
                    title: '约定药量统计(区县)'
                }
            }
        ]
        
    }
];
