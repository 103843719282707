/*
 * @Author: housy
 * @Email: housy@guahao.com
 * @Date: 2019-10-29 16:32:42
 * @Last Modified by: housy
 * @Last Modified time: 2020-04-10 15:17:19
 * @Description: 资质项配置/企业资质项配置
 */
const List = () => import(/* webpackChunkName: "product-list" */ '@/pages/bdc/qualify-config/product/list');
const Detail = () => import(/* webpackChunkName: "product-detail" */ '@/pages/bdc/qualify-config/product/detail');

export default [
    {
        path: 'product/list',
        component: List,
        meta: {
            title: '产品资质项配置'
        }
    },
    {
        path: 'product/list/read',
        component: Detail,
        meta: {
            title: '查看产品资质项',
            display: 'none'
        }
    },
    {
        path: 'product/list/add',
        component: Detail,
        meta: {
            title: '新增产品资质项',
            display: 'none'
        }
    },
    {
        path: 'product/list/edit',
        component: Detail,
        meta: {
            title: '编辑产品资质项',
            display: 'none'
        }
    }

];