/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-09-11 16:42:17
* @Description: 药品和医用耗材采购单位同步管理相关路由
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/mechanism-code-mangage/drug-consumable-unit-manage/list');
const Detail = () => import(/* webpackChunkName: "detail" */ '@/pages/bdc/mechanism-code-mangage/drug-consumable-unit-manage/detail');

export default [
    {
        path: 'drug-consumable-unit-manage/list',
        component: List,
        meta: {
            title: '药品和医用耗材采购单位查询'
        }
    },
    {
        path: 'drug-consumable-unit-manage/list/detail',
        component: Detail,
        meta: {
            title: '药品和医用耗材采购单位查询详情',
            display: 'none'
        }
    }
];
