
import View from '@/components/view';
import Drug from './drug/index'; 
import Consumable from './consumable/index';

export default [
    {
        path: 'quantity-statistics',
        meta: {
            title: '报量管理'
        },
        component: View,
        children: [
            ...Drug,
            ...Consumable
        ]
    }
];
