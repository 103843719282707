/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: housy
 * @Last Modified time: 2019-07-17 10:59:09
* @Description: 产品政策属性管理/基药管理相关路由
*/

const Add = () => import(/* webpackChunkName: "add" */ '@/pages/bdc/product-policy-attr-manage/base-drug-manage/add');
const Edit = () => import(/* webpackChunkName: "edit" */ '@/pages/bdc/product-policy-attr-manage/base-drug-manage/edit');
const List = () => import(/* webpackChunkName: "list" */ '@/pages/bdc/product-policy-attr-manage/base-drug-manage/list');
const Read = () => import(/* webpackChunkName: "read" */ '@/pages/bdc/product-policy-attr-manage/base-drug-manage/read');

export default [
    {
        path: 'base-drug-manage/list',
        component: List,
        meta: {
            title: '基药管理'
        }
    },
    {
        path: 'base-drug-manage/list/edit',
        component: Edit,
        meta: {
            title: '编辑基药',
            display: 'none'
        }
    },
    {
        path: 'base-drug-manage/list/add',
        component: Add,
        meta: {
            title: '新增基药',
            display: 'none'
        }
    },
    {
        path: 'base-drug-manage/list/read',
        component: Read,
        meta: {
            title: '查看基药',
            display: 'none'
        }
    }
];
