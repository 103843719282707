/*
 * @Author: yuanxf
 * @Email: yuanxf@hxmec.com
 * @Date: 2024-10-23 13:59:06
 * @Last Modified by: yuanxf
 * @Last Modified time: 2024-10-23 13:59:06
 * @Description: 医保局采购数据汇总
 * @Route: Route
 */
import View from '@/components/view';

const List = () => import(/* webpackChunkName: "catalog-product-list" */ '@/pages/report/procurement-medical-data-summary/list');

export default [
    {
        path: 'procurement-medical-data-summary',
        meta: {
            title: '采购数据汇总(医保局)'
        },
        component: View,
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '采购数据汇总(医保局)'
                }
            }
        ]
        
    }
];
