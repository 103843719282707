

/*
* @Author: yanghy5
* @Email: yanghy5@guahao.com
* @Date: 2019-07-10 16:35:10
 * @Last Modified by: guoxy1
 * @Last Modified time: 2019-11-04 19:19:53
* @Description: 价格查询/药品和医用耗材价格查询/药品价格查询相关路由
*/

const List = () => import(/* webpackChunkName: "drug-consumable-price-query-list" */ '@/pages/bdc/price-query/drug-consumable-price-query/drug/list');
const Detail = () => import(/* webpackChunkName: "drug-consumable-price-query-detail" */ '@/pages/bdc/price-query/drug-consumable-price-query/drug/detail');

export default [
    {
        path: 'drug/list',
        component: List,
        meta: {
            title: '药品价格查询'
        }
    },
    {
        path: 'drug/list/detail',
        component: Detail,
        meta: {
            title: '药品价格详情',
            display: 'none'
        }
    }
];
